[
  {
    "name": "osmosis",
    "gasPrice": "0.0025uosmo",
    "ownerAddress": "osmovaloper1md9f5524vtmrn64lyv2pdfn7cnkjkklf44vtjz",
    "default": true,
    "maxPerDay": 1,
    "authzAminoExecPreventTypes": [
      "/cosmos.gov.",
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "juno",
    "ownerAddress": "junovaloper193xl2tqh2tjkld2zv49ku5s44ee4qmgr65jcep",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "cosmoshub",
    "ownerAddress": "cosmosvaloper19ecn7ljwp6el2pc5lldyauwv05ufwut9mm38r5"
  },
  {
    "name":"axelar",
    "ownerAddress":"axelarvaloper1j5vxzfx74xlml73e2mz9nn2ultz9jhzxjsakxw"
  },
  {
    "name": "akash",
    "ownerAddress": "akashvaloper1xgnd8aach3vawsl38snpydkng2nv8a4kqgs8hf"
  },
  {
    "name": "chihuahua",
    "ownerAddress": "chihuahuavaloper1m2dxkn94t97m7ah65hv4tg2xu0j2a2k4fdee20",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "gravitybridge"
  },
  {
    "name": "regen",
    "ownerAddress": "regenvaloper1c4y3j05qx652rnxm5mg4yesqdkmhz2f6dl7hhk",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "terra",
    "authzAminoLiftedValues": false
  },
  {
    "name": "terra2",
    "ownerAddress":"terravaloper1pwum4swah9t5mjynalk3f334v4ztxc66k29v25"
  },
  {
    "name": "sentinel",
    "ownerAddress":"sentvaloper1tjgec0ssfrlldmut69xsp8vzljugg0g306aae2"
  },
  {
    "name": "dig"
  },
  {
    "name": "bitcanna"
  },
  {
    "name": "emoney"
  },
  {
    "name": "kava"
  },
  {
    "name": "desmos",
    "ownerAddress":"desmosvaloper1lf3fg79gf2qf6ept7ec22kjd8s6gj3swr2ca0v",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "cryptoorgchain",
    "ownerAddress": "crocncl10mfs428fyntu296dgh5fmhvdzrr2stlaekcrp9",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "evmos",
    "ownerAddress": "evmosvaloper1chx7v975g72xuw8kdpjt94dh35daqqfyc37kys",
    "txTimeout": 120000,
    "maxPerDay": 1,
    "gasPrice": "75000000000aevmos",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]  },
  {
    "name": "sifchain"
  },
  {
    "name": "lumnetwork"
  },
  {
    "name": "stargaze",
    "ownerAddress":"starsvaloper17z2ynnlm8evtjpzvsw3239w75474rc7a4du4l3"
  },
  {
    "name": "comdex",
    "ownerAddress": "comdexvaloper19qz6sgw7llrft2x05lp4swy569e5sla6gl3cuu",
    "apiVersions": {
        "gov": "v1beta1"
    },
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ],
    "authzAminoGenericOnly": true
  },
  {
    "name": "cheqd",
    "ownerAddress":"cheqdvaloper1qsp3a2qd6km9g0hczsac8279wcwmzmvzgvre8w"
  },
  {
    "name": "umee"
  },
  {
    "name": "bitsong"
  },
  {
    "name": "persistence"
  },
  {
    "name": "agoric"
  },
  {
    "name": "impacthub"
  },
  {
    "name": "kichain"
  },
  {
    "name": "sommelier"
  },
  {
    "name": "konstellation"
  },
  {
    "name": "fetchhub"
  },
  {
    "name": "cerberus"
  },
  {
    "name": "secretnetwork",
    "authzAminoGenericOnly": false,
    "ownerAddress":"secretvaloper16k26akna7h295rfjx3278s7xusnt736vy437y8"
  },
  {
    "name": "bostrom"
  },
  {
    "name": "starname"
  },
  {
    "name": "rizon"
  },
  {
    "name": "decentr"
  },
  {
    "name": "assetmantle",
    "ownerAddress": "mantlevaloper170ef5gajk5q8t90cv6qcmfv4ljwzuqtsklsgn6"
  },
  {
    "name": "crescent"
  },
  {
    "name": "meme"
  },
  {
    "name": "cronos",
    "enabled": false
  },
  {
    "name": "harpoon",
    "ownerAddress": "kujiravaloper1vue5lawr3s0au9zj0aqeft5aknx6cjq6w5ghca",
    "testnet": true
  },
  {
    "name": "kujira",
    "ownerAddress": "kujiravaloper1med39q63mwzxgqdzus70lp98krdrfmhhm4v8al",
    "disabledWallets": [
        "cosmostation"
    ],
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "genesisl1",
    "txTimeout": 120000
  },
  {
    "name": "tgrade",
    "enabled": false
  },
  {
    "name": "aioz",
    "enabled": false
  },
  {
    "name": "echelon"
  },
  {
    "name": "kichaintestnet",
    "testnet": true
  },
  {
    "name": "likecoin"
  },
  {
    "name": "passage",
    "ownerAddress": "pasgvaloper1c42pgxrg652f2f26dfgj40a7y5rhkt0wm9hwfj"
  },
  {
    "name": "stride",
    "ownerAddress": "stridevaloper16a28swex9y3knnsls7ae072cu30sj6u5e67np0",
    "authzSupport": false
  },
  {
    "name": "pulsar",
    "ownerAddress": "secretvaloper1mppy58g60n9wkr5494yqd8hmtgcyh5v6pynlfz",
    "testnet": true
  },
  {
    "name": "teritori",
    "ownerAddress": "torivaloper1tjh6wpj6d9kpkfrcyglksevkhhtk9gm7cw7eku",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "rebus"
  },
  {
    "name": "jackal",
    "ownerAddress": "jklvaloper1upt02z2a64m00rh087q8r2esy7mg9yeq29j8gz"
  },
  {
    "name": "oraichain"
  },
  {
    "name": "acrechain"
  },
  {
    "name": "mars",
    "ownerAddress": "marsvaloper14vngc0z9ymvjfdusxt9v4g5we9tckzg2637a44",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "planq"
  },
  {
    "name": "injective",
    "ownerAddress": "injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "xpla"
  },
  {
    "name": "kyve",
    "ownerAddress": "kyvevaloper1egqphd8yjdfv84fl825grwgna0pf2emagdmnz8",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "quicksilver",
    "maxPerDay": 1,
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "chain4energy"
  },
  {
    "name": "coreum",
    "ownerAddress": "corevaloper1py9v5f7e4aaka55lwtthk30scxhnqfa6agwxt8",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "nois",
    "ownerAddress": "noisvaloper198un95fs7wxqkv53zhc8hjcyga8qgswtv82x9w"
  },
  {
    "name": "omniflixhub",
    "ownerAddress": "omniflixvaloper19d782trtcj4yq9lm7nxy3xs3xjg8vjktn3sjsd",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "qwoyn"
  },
  {
    "name": "neutron",
    "enabled": false
  },
  {
    "name": "dydx",
    "ownerAddress": "dydxvaloper15wphegl8esn7r2rgj9j3xf870v78lxg8yfjn95"
  },
  {
    "name": "celestia",
    "ownerAddress": "celestiavaloper1dsyuamue2p3l0z753pyrtegs52ftrg960re06j"
  },
  {
    "name": "dymension",
    "ownerAddress": "dymvaloper1z2uv28s9ewxg0ch45m2v27ke76tf7hvw398ggu"
  },
  {
    "name": "humans"
  },
  {
    "name": "saga"
  },
  {
    "name": "aura",
    "ownerAddress": "auravaloper1dyw4kvnfzj9geh75jlw4z2tgmj5g9f35wu63pp"
  },
  {
    "name": "migaloo",
    "ownerAddress": "migaloovaloper1z2zhers48wdczszz3utd9gphuw6pp5zdwuhqg4"
  },
  {
    "name": "quasar",
    "ownerAddress": "quasarvaloper1qpr2ezeut3xmarqsxddyld0sdlkrppgpq6alhj"
  }
]
